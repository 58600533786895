@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('./poppins/Poppins-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-Black.woff2') format('woff2'),
        url('./poppins/Poppins-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-Italic.woff2') format('woff2'),
        url('./poppins/Poppins-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-Thin.woff2') format('woff2'),
        url('./poppins/Poppins-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-LightItalic.woff2') format('woff2'),
        url('./poppins/Poppins-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-Light.woff2') format('woff2'),
        url('./poppins/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-Bold.woff2') format('woff2'),
        url('./poppins/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('./poppins/Poppins-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-ThinItalic.woff2') format('woff2'),
        url('./poppins/Poppins-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('./poppins/Poppins-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-BlackItalic.woff2') format('woff2'),
        url('./poppins/Poppins-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-Medium.woff2') format('woff2'),
        url('./poppins/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-ExtraBold.woff2') format('woff2'),
        url('./poppins/Poppins-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-SemiBold.woff2') format('woff2'),
        url('./poppins/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-ExtraLight.woff2') format('woff2'),
        url('./poppins/Poppins-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-MediumItalic.woff2') format('woff2'),
        url('./poppins/Poppins-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-BoldItalic.woff2') format('woff2'),
        url('./poppins/Poppins-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('./poppins/Poppins-Regular.woff2') format('woff2'),
        url('./poppins/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

