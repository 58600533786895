$wallet: 'wallet';

.#{$wallet} {
  &-item {
    display: flex;
    align-items: center;
    height: 29px;
    width: fit-content;
    padding: 6px 9px 6px 6px;
    background-color: var(--x-dark-blue);
    border-radius: 5px;

    &-big {
      height: 64px;
      width: fit-content;
      padding: 5px 18px;

      .#{$wallet}-ico {
        width: 51px;
        height: 51px;
      }

      .#{$wallet}-label {
        font-size: 31px;
      }
    }
  }

  &-ico {
    width: 21px;
    height: 21px;
    background-size: contain !important;
    margin-right: 7px;

    &-xhype {
      border-radius: 50%;
      background: #fff url(./../../public/App/logon.png) center center/12px 12px
        no-repeat !important;
    }

    &-wallet {
      background: url(./../../public/App/wallet.png) no-repeat;
    }

    &-usdt {
      background: url(./../../public/App/Tether-USDT.png) no-repeat;
    }
  }

  &-label {
    font-size: 13px;
    color: var(--x-white);
    text-decoration: none;
    width: fit-content;
    line-height: 15px;
    & a {
      color: var(--x-white);
      text-decoration: none;
    }
  }
}

// @media (min-width: 992px) {
//   .#{$wallet} {
//     &-item {
//       &-big {
//         width: 393px;
//       }
//     }
//   }
// }
