@import url(./theme/_theme.scss);

body {
  background: #191919;
  color: #000;
  margin: 0;
  font-family: 'Poppins';
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  font-size: 16px;
  overflow: hidden;
}

html {
  height: 100%;
}