.container {
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App {
  &-main {
    &-text {
      display: flex;
      justify-content: end;

      &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-right: 0px;
        margin-top: -145px;
        color: var(--x-white);
        padding: 0 24px;
      }
    }

    &-title {
      font-size: 32px;
    }

    &-subtitle {
      font-size: 16px;
    }
  }

  &-hand {
    position: absolute;
    right: 100px;
    bottom: -520px;
    z-index: -1;
  }

  &-purple-elipse {
    background: url(./../public/App/elipse-1.png);
    width: 500px;
    height: 500px;
    position: absolute;
    background-size: contain;
    right: -30px;
    bottom: -225px;
    z-index: -2;
  }

  &-pink-elipse {
    background: url(./../public/App/elipse-2.png);
    width: 500px;
    height: 500px;
    position: absolute;
    background-size: contain;
    right: -250px;
    top: -300px;
    z-index: -1;
    overflow: hidden;
  }

  &-blue-elipse {
    background: url(./../public/App/elipse-3.png);
    width: 500px;
    height: 500px;
    position: absolute;
    background-size: contain;
    left: -154px;
    top: -130px;
    z-index: -1;
  }

  &-header {
    display: block;
    padding: 40px 24px;
    &-menu {
      flex: 1;
      margin-left: 0px;
      display: flex;
      height: 40px;
      align-items: center;
      padding: 20px 0;
      flex-wrap: wrap;

      &-item {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        &:not(:first-child) {
          margin-left: 18px;
        }
        &:last-child {
          background: none;
          border: none;
          display: flex;
          cursor: pointer;
          margin: 10px 0;
          padding-left: 0;
        }
      }

      &-ico {
        width: 21px;
        height: 21px;
        margin-right: 7px;
        &-size {
          width: 30px;
          height: 30px;
          margin-right: 7px;
        }
        &-div {
          display: flex;
          align-items: center;
        }
        &-pre {
          width: 25px;
          height: 25px;
        }
        &-pre1 {
          width: 35px;
          height: 25px;
          margin-right: -5px;
        }
      }

      &-label {
        font-size: 15px;
        color: var(--x-white);
      }
    }

    &-wallet {
      align-self: flex-end;
      margin-top: 20px;
      &-info {
        display: block;
        justify-content: flex-end;
        align-items: center;
        margin-top: 75px;
        .wallet-item {
          &:not(:first-child) {
            margin-top: 10px;
            margin-left: 0px;
            width: 135px !important;
          }
        }

        &-icon {
          background: url(./../public/App/setting.png) no-repeat;
          width: 22px;
          height: 22px;
          background-size: contain;
          margin-left: 13px;
        }
      }

      &-rewards {
        margin-top: 18px;
        margin-right: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &-label {
          color: var(--x-white);
          font-size: 12px;
          margin-bottom: 6px;
        }
      }
    }
  }

  &-popUp {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-modeTest {
    & button {
      border: none;
      border-radius: 5px;
      padding: 10px 15px;
      cursor: pointer;
      margin-top: 20px;
    }
  }

  &-connectWallet {
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
  }

  &-footer {
    text-align: center;
    color: #fff;
    margin-bottom: -610px;
    margin-top: 470px;
    padding-bottom: 20px;
  }
}

.PopUp {
  z-index: 5;
  position: absolute;
  background-color: white;
  width: 300px;
  height: 360px;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  &-close {
    width: 100%;
    text-align: end;
    color: white;
    background: none;
    border: none;
    position: absolute;
    top: -30px;
    font-size: 25px;
    font-weight: bolder;
    cursor: pointer;
    right: 0px;
  }
  &-title {
    color: var(--x-pink);
    font-weight: 600;
    font-size: 35px;
  }
  &-transfer {
    display: flex;
    justify-content: center;
    align-items: end;
    &-input {
      & p {
        color: var(--x-grey);
        font-size: 12px;
        margin-bottom: 2px;
      }
      & input {
        width: 180px;
        height: 30px;
        margin-right: 5px;
      }
    }
    &-button {
      width: fit-content;
      height: 36px;
      background-color: var(--x-dark-blue);
      color: var(--x-white);
      border: none;
      border-radius: 5px;
      cursor: pointer;
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
  &-subtitle {
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
  }
  &-welcome {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    color: #000 !important;
  }
}

@media (min-width: 768px) {
  .container {
    overflow-x: visible;
  }
  .App {
    &-hand {
      right: 253px;
      bottom: -124px;
    }
    &-purple-elipse {
      width: 1075px;
      height: 1075px;
      right: -232px;
      bottom: -385px;
    }
    &-pink-elipse {
      width: 1575px;
      height: 1569px;
      right: -787px;
      top: -1306px;
    }
    &-blue-elipse {
      width: 1076px;
      height: 1076px;
      left: -354px;
      top: -668px;
    }
    // &-popUp {
    //   top: 30%;
    //   left: 35%;
    //   transform: translate(-50%, -50%);
    // }
  }
}

@media (min-width: 992px) {
  .container {
    overflow: hidden;
  }
  .App {
    &-main {
      &-text {
        &-content {
          width: 570px;
          margin-right: 575px;
          text-align: right;
          padding: 0;
        }
      }
      &-title {
        font-size: 50px;
      }

      &-subtitle {
        font-size: 23px;
      }
    }
    &-header {
      display: flex;
      padding: 40px 76px;
      &-menu {
        margin-left: 40px;
        padding: 0;
        margin-top: -15px;
        &-item {
          margin-left: 28px;
          &:last-child {
            margin: 0 0 0 28px;
          }
        }
      }
      &-wallet {
        margin-top: 0;
        &-info {
          display: flex;
          align-items: center;
          margin-top: 0px;
          .wallet-item {
            &:not(:first-child) {
              margin-top: 0px;
              margin-left: 13px;
            }
          }
        }
        &-rewards {
          margin-right: 35px;
          align-items: flex-end;
        }
      }
    }
    &-footer {
      margin-bottom: 0px;
      margin-top: 0px;
    }
    // &-popUp{
    //   left: 38%;
    // }
  }
  .PopUp {
    width: 370px;
    height: 360px;
  }
}
