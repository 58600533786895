.container {
    margin: 0 auto;
    height: 100%;
}

@media screen and (min-width: 768px) {

    //For Laptops
    .container {
        width: 100%;
    }
}

@media screen and (min-width: 992px) {

    //For Large Laptops
    .container {
        width: 100%;
    }
}

@media screen and (min-width: 1280px) {

    //For Big TV's (HD Screens) 
    .container {
        width: 1250px;
    }
}


@media screen and (min-width: 1920px) {

    //For Projectors or Higher Resolution Screens (Full HD)
    .container {
        width: 1890px;
    }
}

@media screen and (min-width: 3840px) {

    //For 4K Displays (Ultra HD)
    .container {
        width: 3810px;
    }
}